<template>
  <div
    v-if="isShow"
    class="qt-wrap"
  >
    <div class="qt-content">
      <h3>合格投资者确认</h3>
      <p>
        若您有意进行高端理财管理产品投资。 请确认您复核法规规定的“合格投资者”标准，即具备相应的风险识别能力和风险承担能力，投资于单只产品符合产品合同约定的起投金额，且需具有2 年以上投资经历，并满足以下条件之一：<br>
        1、家庭金融资产<span>≥500万</span>；<br>
        2、家庭 金融净资产<span>≥300万</span>；<br>
        3、近<span>3年</span>本人年均收入<span>≥50万</span>。<br>
        并且，您对众惠基金及其平台销售的私募资产管理产品有一定的了解，是众惠基金的优质注册客户。
      </p>
      <div class="btn-wrap">
        <img
          src="@/assets/images/myFavors/button.png"
          @click="sure"
        >
      </div>
      <img
        class="zhlog"
        src="@/assets/images/myFavors/zhlog.png"
      >
      <i
        class="iconfont icon-simu-close2"
        @click="close"
      />
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        isShow: false
      }
    },
    methods: {
      sure() {
        this.$emit('sure')
      },
      open() {
        console.log(123)
        this.isShow = true
      },
      close() {
        this.isShow = false
      }
    },
  }
</script>

<style lang="less" scoped>
* {
  box-sizing: border-box;
}
.qt-wrap {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.2);
  z-index: 99;
  overflow: hidden;
  .qt-content {
    width: 690px;
    height: 520px;
    margin: 90px auto;
    padding: 40px 30px;
    position: relative;
    background: url('../../assets/images/myFavors/hgbg.png');
    background: #ffffff;
    border-radius: 14px;
    
    background-size: 100% 100%;
    &>h3 {
      width: 100%;
      font-weight: normal;
      text-align: center;
      font-size: 20px;
      color: #1F1F1F;
    }
    &>p {
      margin-top: 40px;
      font-size: 16px;
      line-height: 32px;
      color: #25293D;
      &>span {
        color: #CE9B63;
      }
    }
    .btn-wrap {
      margin-top: 28px;
      width: 100%;
      text-align: center;
      &>img {
        width: 182px;
        height: 42px;
      }
    }
    &>.zhlog {
      position: absolute;
      right: 30px;
      bottom: 23px;
      width: 63px;
      height: 53.5px;
    }
    .iconfont {
      font-size: 50px;
      color: #fff;
      position: absolute;
      left: calc(50% - 25px);
      bottom: -74px;
    }
  }
}
</style>