<template>
  <div class="app-wrap">
    <h3>
      <span>我的账户</span>
      <span @click="handleLoginOut">退出登录</span>
    </h3>
    <div class="info-wrap">
      <div class="avator">
        <img :src="userInfo.avatar">
        <div>
          <span>{{ userInfo.clientName }}</span>
          <span @click="$router.push({name: 'PersonalInfo'})">
            去个人信息完善
            <img src="@/assets/images/myFavors/bc7.png">
          </span>
        </div>
      </div>
      <div class="info-right">
        <div class="top">
          <div>
            <span>{{ userInfo.bankCount }}</span>
            <span>张</span>
          </div>
          <div>
            <span>
              {{
                userInfo.mobileTel ? userInfo.mobileTel.slice(0,3) + '****' + userInfo.mobileTel.slice(-4) : ''
              }}
            </span>
          </div>
        </div>
        <div class="center">
          <div>
            <span>银行卡</span>
          </div>
          <div>
            <span>手机</span>
          </div>
        </div>
        <div class="bottom">
          <div>
            <span @click="$router.push({name: 'BankCardManagement'})">管理</span>
          </div>
          <div>
            <span @click="$router.push({name: 'UpdateBindPhone'})">修改</span>
          </div>
        </div>
      </div>
    </div>
    <div class="idcard-wrap">
      <div class="card-info">
        <span>证件类型：身份证</span>
        <span>{{
          userInfo.idNo ? userInfo.idNo.slice(0,3) + '***********' + userInfo.idNo.slice(-4) : ''
        }}</span>
      </div>
      <div class="card-btn">
        <span @click="$router.push({name: 'PersonalDataSupply'})" v-if="userInfo.addDataFlag === '0'">个人资料补充</span>
      </div>
    </div>
    <div class="about-wrap">
      <h3>密码设备管理</h3>
      <div class="about-item">
        <div>
          <img src="@/assets/images/myFavors/bc8.png">
          <span>登录密码</span>
        </div>
        <div>
          <img src="@/assets/images/myFavors/bc10.png">
          <span>已设置</span>
        </div>
      </div>
      <div class="about-item">
        <div>
          <img src="@/assets/images/myFavors/bc9.png">
          <span>交易密码</span>
        </div>
        <div>
          <template v-if="userInfo.tradePwdFlag === '1'">
            <img src="@/assets/images/myFavors/bc10.png">
            <span>已设置</span>
          </template>
          
          <span
            v-else
            class="item-btn"
            style="cursor: pointer;"
            @click="$router.push({name: 'OpenAccount'})"
          >设置</span>
        </div>
      </div>
    </div>
    <div class="about-wrap">
      <h3>投资相关</h3>
      <div class="about-item">
        <div>
          <img src="@/assets/images/myFavors/bc11.png">
          <span>合格投资者确认</span>
        </div>
        <div>
          <template v-if="userInfo.clientHgFlag === '1' || userInfo.clientHgFlag === '0'">
            <img src="@/assets/images/myFavors/bc10.png">
            <span>已确认</span>
          </template>
          <span
            v-else
            class="item-btn"
            @click="handleQualOpen"
          >去确认</span>
        </div>
      </div>
      <div class="about-item">
        <div>
          <img src="@/assets/images/myFavors/bc12.png">
          <span>分红设置</span>
        </div>
        <div>
          <span
            class="item-btn"
            @click="$router.push({name: 'DividentFit'})"
          >设置</span>
        </div>
      </div>
      <div class="about-item">
        <div>
          <img src="@/assets/images/myFavors/bc13.png">
          <span>资产证明</span>
        </div>
        <div>
          <span
            class="item-btn"
            @click="$router.push({name: 'AssetProve'})"
          >去申请</span>
        </div>
      </div>
    </div>
    <div class="personal-wrap">
      <h3>个人信息</h3>
      <div class="personal-item">
        <div>姓名</div>
        <span>{{ userInfo.clientName }}</span>
      </div>
      <div class="personal-item">
        <div>性别</div>
        <span>{{ userInfo.clientGender === '1' ? '女' : '男' }}</span>
      </div>
      <div class="personal-item">
        <div>年龄</div>
        <span>{{ getAge(userInfo.birthday) }}</span>
      </div>
      <div class="personal-item">
        <div>证件类型</div>
        <span>{{ idCardType }}</span>
      </div>
      <div class="personal-item">
        <div>证件号码</div>
        <span>{{ 
          userInfo.idNo ? userInfo.idNo.slice(0, 4) + '************' + userInfo.idNo.slice(-3) : ''
        }}</span>
      </div>
      <div class="personal-item">
        <div>证件有效期</div>
        <span>{{ 
          userInfo.idEnddate ? userInfo.idEnddate == '99991231' ?  '长期有效'
                                                                : userInfo.idEnddate.slice(0, 4) + '-' + userInfo.idEnddate.slice(4, 6) + '-' + userInfo.idEnddate.slice(-2)
                             : ''
        }}</span>
      </div>
      <div class="personal-item">
        <div>证件照</div>
        <span :class="userInfo.photoFlag === '1' ? 'blue' : ''">{{ userInfo.photoFlag === '1' ? '已上传' : '未上传' }}</span>
      </div>
      <div class="personal-item">
        <div>国籍</div>
        <span>{{ nationalText }}</span>
      </div>
      <div class="personal-item">
        <div>邮编</div>
        <span>{{ userInfo.zipcode }}</span>
      </div>
      <div class="personal-item">
        <div>地址</div>
        <span>{{ userInfo.idAddress }}</span>
      </div>
      <div class="personal-item">
        <div>邮箱</div>
        <span>{{ userInfo.email }}</span>
      </div>
      <div class="personal-item">
        <div>职业类别</div>
        <span>{{ jobText }}</span>
      </div>
      <div class="personal-item">
        <div>年收入</div>
        <span>{{ incomeText }}</span>
      </div>
      <div class="personal-item">
        <div>账户实际受益人</div>
        <span>{{ displayBene }}</span>
      </div>
      <div class="personal-item">
        <div>税收居民身份声明</div>
        <span>{{ displayTaxTxt }}</span>
      </div>
    </div>

    <qualifiedDialog
      ref="qual"
      @sure="handleQualSure"
    />
  </div>
</template>

<script>
  import qualifiedDialog from '@/components/qualifiedDialog/index'
  import { accreditedInvestor } from '@/api/myAcc'
  import { mapActions } from 'vuex'
  export default {
    components: {
      qualifiedDialog,
    },
    data() {
      return {
        taxDics:[
					{
						value:'0',
						text: '仅为中国税收居民'
					},
					{
						value:'1',
						text: '仅为非中国税收居民'
					},
					{
						value:'2',
						text: '既是中国税收居民也是其他国家（地区）'
					},
				],
        // 证件类型字典
				sysIdType: [],
				// 国籍字典
				nationalDics: [],
				// 职业类别
				jobDics: [],
				// 年收入字典
				incomeDics: [],
        userInfo: {},
      }
    },
    computed: {
      idCardType() {
				return (this.sysIdType.find(item => item.dictValue === this.userInfo.idKindGb) || {}).dictLabel
			},
			nationalText() {
				return (this.nationalDics.find(item => item.dictValue === this.userInfo.fundNationality) || {}).dictLabel
			},
			jobText() {
				return (this.jobDics.find(item => item.dictValue === this.userInfo.ofundProfCode) || {}).dictLabel
			},
			incomeText() {
				return (this.incomeDics.find(item => +item.dictValue === +this.userInfo.income) || {}).dictLabel
			},
      displayBene() {
        if (this.userInfo.beneficiaryType == '') return ''
        return this.userInfo.beneficiaryType === '1' ? '本人' : '他人'
      },
      displayTaxTxt() {
        return (this.taxDics.find(item => item.value === this.userInfo.taxIdentity) || {}).text
      }
    },
    created () {
      this.userInfo = JSON.parse(localStorage.getItem('customerInfo')) || {}
      this.getDicts('sys_id_type').then(res => {
				this.sysIdType = res.data
			})
			this.getDicts('sys_user_nationality').then(res => {
				this.nationalDics = res.data
			})
			this.getDicts('sys_user_job_type').then(res => {
				this.jobDics = res.data
			})
			this.getDicts('income_type').then(res => {
				this.incomeDics = res.data
			})
    },
    methods: {
      ...mapActions(['FedLogOut']),
      handleLoginOut() {
        this.FedLogOut()
      },
      handleQualOpen() {
        this.$refs.qual.open()
      },
      handleQualSure() {
        accreditedInvestor().then(() => {
          this.$store.dispatch('GetInfo').then(() => {
            this.$message({
              message: '有效期修改成功',
              type: 'success'
            });
            const userInfo = JSON.parse(localStorage.getItem('customerInfo')) || {}
            this.$set(this, 'userInfo', userInfo)
            this.$refs.qual.close()
          })
          
        })
      },
      getAge(str) {
        var returnStr="0"
        if(str == ""|| str==null){
          returnStr = "0"
        }else{
          let workTime = new Date(str.replace(/-/g, "/"));
          let d = new Date();
          let age = d.getFullYear() - workTime.getFullYear() - (d.getMonth() < workTime.getMonth() || (d.getMonth() == workTime.getMonth() && d.getDate() < workTime.getDate()) ? 1 : 0);
          returnStr = age + 1;
        }
        return returnStr;
			},
    },
  }
</script>

<style lang="less" scoped>
@import url('@/assets/css/zc-common.less');

.app-wrap > h3 {
  width: 100%;
  font-weight: bold;
  font-size: 20px;
  color: #1F1F1F;
  .flex(space-between, center, row);
  span {
    &:last-child {
      .inline-flex(center, center, row);
      width: 82px;
      height: 30px;
      border: 0.6px solid #CE9B63;
      box-sizing: border-box;
      border-radius: 2px;
      font-size: 16px;
      font-weight: normal;
      color: #CE9B63;
      cursor: pointer;
    }
  }
}

.info-wrap {
  .content();
  .flex(space-between, flex-start, row);
  .avator {
    .flex(space-between, flex-start, row);
    & > img {
      width: 92px;
      height: 92px;
      border-radius: 50%;
    }
    & > div {
      height: 92px;
      .flex(center, flex-center, column);
      margin-left: 30px;
      
      span {
        &:first-child {
          font-size: 24px;
          color: #1F1F1F;
        }
        &:last-child {
          font-size: 14px;
          color: #CE9B63;
          margin-top: 10px;
          cursor: pointer;
          .flex(space-between, flex-start, row);
          & > img {
            margin-left: 8px;
            width: 19px;
            height: 19px;
          }
        }
      }
    }
  }
  .info-right {
    width: 292px;
    .flex(center, center, column);
    &>div {
      width: 100%;
      .flex(space-between, flex-start, row);
      &.top> div{
        height: 37px;
        &:first-child {
          width: 96px;
          .flex(center, center, row);
          span {
            font-size: 14px;
            color: #A5A5A5;
            &:first-child {
              font-size: 32px;
              font-weight: bold;
              color: #CE9B63;
              margin-right: 4px;
            }
            &:last-child {
              margin-top: 6px;
            }
          }
        }
        &:last-child {
          width: 110px;
          .flex(center, center, row);
          font-size: 20px;
          color: #1F1F1F;
        }
      }
      &.center {
        margin-top: 15px;
      }
      &.center > div{
        .flex(center, center, row);
        font-size: 14px;
        color: #1F1F1F;
        &:first-child {
          width: 96px;
        }
        &:last-child {
          width: 110px;
        }
      }
      &.bottom {
        margin-top: 20px;
        &> div {
          .flex(center, center, row);
          font-size: 14px;
          &:first-child {
            width: 96px;
          }
          &:last-child {
            width: 110px;
          }
          &>span {
            .flex(center, center, row);
            width: 96px;
            height: 30px;
            background: #F9F9F9;
            border: 0.6px solid #E0E2E8;
            box-sizing: border-box;
            border-radius: 2px;
            font-size: 14px;
            color: #25293D;
            cursor: pointer;
          }
        }
      }
    }
  }
}
.idcard-wrap {
  .content();
  .flex(space-between, center, row);
  .card-info {
    .flex(center, flex-start, column);
    &>span{
      color: #1F1F1F;
      &:last-child {
        font-size: 14px;
      }
      &:last-child {
        font-size: 32px;
        margin-top: 20px;
      }
    }
  }
  .card-btn {
    flex: 1;
    .flex(center, center, row);
    &>span {
      .inline-flex(center, center, row);
      width: 189px;
      height: 40px;
      background: #DEB87B;
      border-radius: 2px;
      color: #FFFFFF;
      font-size: 18px;
      cursor: pointer;
    }
  }
}
.about-wrap {
  .content();
  &>h3 {
    font-size: 18px;
    color: #1F1F1F;
    margin-bottom: 30px;
  }
  .about-item {
    width: 100%;
    height: 30px;
    margin-top: 20px;
    .flex(space-between, center, row);
    div {
      .flex(flex-start, center, row);
      font-size: 14px;
      img {
        width: 18px;
        height: 18px;
        margin-right: 8px;
      }
      &:first-child {
        width: 124px;
        color: #1F1F1F;
      }
      &:last-child {
        color: #CE9B63;
        flex: 1;
        padding-left: 280px;
        .item-btn {
          .inline-flex(center, center, row);
          width: 96px;
          height: 30px;
          background: #F9F9F9;
          border: 1px solid #E0E2E8;
          box-sizing: border-box;
          border-radius: 2px;
          font-size: 14px;
          color: #25293D;
          cursor: pointer;
        }
      }
    }
  }
}
.personal-wrap {
  .content();
  border-bottom: none;
  &>h3 {
    font-size: 18px;
    color: #1F1F1F;
    margin-bottom: 30px;
  }
  .personal-item {
    width: 100%;
    height: 16px;
    .flex(flex-start, center, row);
    margin-bottom: 30px;
    &>div {
      width: 136px;
      font-size: 16px;
      color: #A5A5A5;
    }
    &>span {
      margin-left: 76px;
      font-size: 14px;
      color: #25293D;
      &.blue {
        color: #0366C4;
      }
    }
  }
}
</style>