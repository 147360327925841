import request from '@/utils/request'
// 修改头像
export function updataAvatar(data) {
	return request({
    url: '/api/sale/customer/avatar',
    method: 'POST',
		data
	})
}

// 修改邮编
export function updataZipcode(data) {
	return request({
		url: '/api/sale/customer/zipcode',
		method: 'POST',
		data
	})
}

// 修改电子邮箱
export function updateEmail(data) {
  return request({
    url: '/api/sale/customer/email',
    method: 'POST',
	data
  })
}

// 修改职业类别
export function updataOfound(data) {
	return request({
		url: '/api/sale/customer/ofoundProfCode',
		method: 'POST',
		data
	})
}
// 修改年收入
export function updataIncome(data) {
	return request({
		url: '/api/sale/customer/income',
		method: 'POST',
		data
	})
}

// 选择税收居民身份
export function residentStatus(data) {
  return request({
    url: '/api/sale/securitycenter/editTaxIdentity',
    method: 'POST',
	data
  })
}

// 修改账户实际受益人
export function updateUserBeneficiary(data) {
	return request({
		url: '/api/sale/customer/editBeneInfo',
		method: 'POST',
		data
	})
}


// // ocr识别
// export function ocrIdCard(data) {
//   return request({
//     url: '/api/sale/ocr/idCard',
//     method: 'POST',
// 	data
//   })
// }

// 修改有效期
export function upLoadIDCard(data) {
  return request({
    url: '/api/sale/customer/idCardPeriod',
    method: 'POST',
	data
  })
}

// 修改通讯地址
export function updateAddress(data) {
  return request({
    url: '/api/sale/customer/address',
    method: 'POST',
	data
  })
}


// 查询当前支付通道及支持银行
export function getPayChannel(data) {
  return request({
    url: '/api/sale/sms/getPayChannel',
    method: 'POST',
	data
  })
}

// 校验四要素信息进行短信签约
export function getSmsSignCode(data) {
  return request({
    url: '/api/sale/account/smsSign',
    method: 'POST',
		data
  })
}

// 验证四要素鉴权之后的短信验证码
export function verifCodeForBank(data) {
  return request({
    url: '/api/sale/account/verifCodeForBank',
    method: 'POST',
		data
  })
}

// 填写交易密码,进行开户
export function openAccount(data) {
  return request({
    url: '/api/sale/account/openAccount',
    method: 'POST',
	data
  })
}

// 资料补充
export function clientInfoMod(data) {
  return request({
    url: '/api/sale/trade/subscribe/clientInfoMod',
    method: 'POST',
	data
  })
}

// 合格投资者确认
export function accreditedInvestor(data) {
	return request({
		url: '/api/sale/customer/confirmHg',
		method: 'POST',
		data
	})
}

// ocr身份证识别
export function ocrIdCard(data) {
  return request({
    url: '/api/system/ocr/do',
    method: 'POST',
	data
  })
}


// 查询招贤纳士内容
export function findRecruit(data) {
  return request({
      url: '/api/sale/platfcfg/info/findRecruit',
      method: 'post',
      data: data
  })
}